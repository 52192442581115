var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"swiper",rawName:"v-swiper:lastOrders",value:(_vm.swiperOption),expression:"swiperOption",arg:"lastOrders"}],staticClass:"min-h-min !py-4 pb-16"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.photos),function(photo,index){return _c('figure',{key:index,staticClass:"swiper-slide portfolio-item max-w-full border border-slate-200 bg-white text-center shadow lg:max-w-fit"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          photo.on_progress === false || photo.on_progress === undefined
        ),expression:"\n          photo.on_progress === false || photo.on_progress === undefined\n        "}],staticClass:"relative z-10"},[_c('span',{staticClass:"label absolute top-0 right-0 rounded bg-indigo-500 shadow-inner"},[_c('IconOnFinal'),_vm._v(" "),_c('span',{staticClass:"ml-0.5 font-bold text-white"},[_vm._v("Final")])],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(photo.on_progress),expression:"photo.on_progress"}],staticClass:"relative z-10"},[_c('span',{staticClass:"label absolute top-0 right-0 rounded bg-indomascot-yellow shadow-inner"},[_c('IconOnProgress'),_vm._v(" "),_c('span',{staticClass:"ml-0.5 font-bold text-white"},[_vm._v("On Progress")])],1)]),_vm._v(" "),_c('a',{attrs:{"href":`${photo.photo}/m/628x628`,"rel":"lightbox noopener","target":"_blank","data-featherlight":"image","alt":photo.caption}},[_c('NuxtImg',{attrs:{"provider":"storyblok","width":"369","height":"369","src":photo.photo,"alt":`${photo.caption} ${photo.caption !== '' ? '-' : ''}
          ${new Date(photo.uploaded_date).toLocaleDateString(
            _vm.$i18n.locale,
            _vm.dateOptions
          )}`,"title":photo.caption,"placeholder":[100, 100, 10]}})],1),_vm._v(" "),_c('figcaption',{staticClass:"mb-2 hidden h-7 text-center text-sm"},[_vm._v("\n        "+_vm._s(photo.caption)+"\n      ")])])}),0),_vm._v(" "),_c('div',{staticClass:"swiper-button-prev left-[4%] -mt-16 lg:left-[2%]"}),_vm._v(" "),_c('div',{staticClass:"swiper-button-next right-[4%] -mt-16 lg:right-[2%]"}),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})])
}
var staticRenderFns = []

export { render, staticRenderFns }