import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c2991ed6&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.10.1_mi7kfaeh4nmupiqclubmvzt3ru/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionHeader: require('/opt/buildhome/repo/components/Section/Header.vue').default,SectionClients: require('/opt/buildhome/repo/components/Section/Clients.vue').default,SectionDefinition: require('/opt/buildhome/repo/components/Section/Definition.vue').default,SectionVisionMision: require('/opt/buildhome/repo/components/Section/VisionMision.vue').default,SectionFeaturedService: require('/opt/buildhome/repo/components/Section/FeaturedService.vue').default,LatestOrders: require('/opt/buildhome/repo/components/LatestOrders.vue').default,SectionPortfolioButton: require('/opt/buildhome/repo/components/Section/PortfolioButton.vue').default})
