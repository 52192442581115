
export default {
  data() {
    return {
      showKeunggulanMasker: false,
      showKeunggulanWaktu: false,
      showKeunggulanBahan: false,
      intersectionOptions: [],
      observer: null,
      benang: {
        duration: Math.floor(Math.random() * 1000) + 3000,
        delay: Math.floor(Math.random() * 1000) + 200,
      },
      speedometer: {
        duration: Math.floor(Math.random() * 1000) + 3000,
        delay: Math.floor(Math.random() * 1000) + 200,
      },
      kipasMasker: {
        duration: Math.floor(Math.random() * 1000) + 3000,
        delay: Math.floor(Math.random() * 1000) + 200,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      const keunggulanBahan = document.getElementById("keunggulan-bahan");
      const keunggulanWaktu = document.getElementById("keunggulan-waktu");
      const keunggulanMasker = document.getElementById("keunggulan-masker");
      this.observer = new IntersectionObserver(
        this.onIntersecting,
        this.intersectionOptions
      );
      this.observer.observe(keunggulanBahan);
      this.observer.observe(keunggulanWaktu);
      this.observer.observe(keunggulanMasker);
    });
  },
  beforeUnmount() {
    this.observer.disconnect();
  },
  methods: {
    onIntersecting: function (entries, observer) {
      entries.forEach((entry) => {
        if (entry.target.id === "keunggulan-bahan") {
          if (entry.isIntersecting) {
            this.showKeunggulanBahan = true;
            observer.unobserve(entry.target);
          }
        }
        if (entry.target.id === "keunggulan-waktu") {
          if (entry.isIntersecting) {
            this.showKeunggulanWaktu = true;
            observer.unobserve(entry.target);
          }
        }
        if (entry.target.id === "keunggulan-masker") {
          if (entry.isIntersecting) {
            this.showKeunggulanMasker = true;
            observer.unobserve(entry.target);
          }
        }
      });
    },
  },
};
