
import { directive } from "vue-awesome-swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";

export default {
  name: "LatestOrders",
  directives: {
    swiper: directive,
  },
  data() {
    return {
      dateOptions: {
        year: "numeric",
        month: "long",
        day: "numeric",
      },
      swiperOption: {
        effect: "coverflow",
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        initialSlide: this.$device.isDesktopOrTablet ? 1 : 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        centeredSlides: true,
        grabCursor: true,
        slidesPerView: "auto",
        spaceBetween: 0,
        loop: false,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        },
        // slidesOffsetBefore: "6",
        mousewheel: {
          invert: false,
          forceToAxis: true,
        },
      },
      story: { content: {} },
      lastUpdated: null,
      photos: null,
    };
  },
  async fetch() {
    this.story = await this.$storyapi
      .get("cdn/stories/badut-maskot", {
        version: "draft",
      })
      .then((res) => {
        this.lastUpdated = res.data.story.published_at;
        this.photos = res.data.story.content.body[0].columns;
        this.photos = this.photos.reverse();
        this.photos.length = 10;
        return res.data.story;
      })
      .catch((res) => {
        if (!res.response) {
          console.error(res);
          context.error({
            statusCode: 404,
            message: "Failed to receive content from API",
          });
        } else {
          console.error(res.response.data);
          context.error({
            statusCode: res.response.status,
            message: res.response.data,
          });
        }
      });
  },
  mounted() {
    this.$storybridge(() => {
      const storyblokInstance = new StoryblokBridge();

      // Use the input event for instant update of content
      storyblokInstance.on("input", (event) => {
        console.log(this.story.content);
        if (event.story.id === this.story.id) {
          this.story.content = event.story.content;
        }
      });

      // Use the bridge to listen the events
      storyblokInstance.on(["published", "change"], () => {
        // window.location.reload()
        this.$nuxt.$router.go({
          path: this.$nuxt.$router.currentRoute,
          force: true,
        });
      });
    });
  },
};
