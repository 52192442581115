
export default {
  mounted() {
    this.$nextTick(() => {
      const video = document.getElementById("inilahmaskot");
      video.addEventListener("ended", function () {
        const v = video.currentSrc;
        video.src = "";
        video.src = v;
      });
    });
  },
};
