
export default {
  data() {
    return {
      clients: {
        client1: {
          id: "grab",
          src: "https://a.storyblok.com/f/118728/120x55/fc122308dc/grab.png",
          width: "120",
          height: "55",
          sizes: "xs:120px lg:120px",
        },
        client2: {
          id: "konimex",
          src: "https://a.storyblok.com/f/118728/214x55/cdaf31b2aa/konimex.png",
          width: "213",
          height: "55",
          sizes: "xs:213px lg:213px",
        },
        client3: {
          id: "lazada",
          src: "https://a.storyblok.com/f/118728/170x55/7d98053c86/lazada.png",
          width: "170",
          height: "55",
          sizes: "xs:170px lg:170px",
        },
        client4: {
          id: "ojk",
          src: "https://a.storyblok.com/f/118728/137x55/d59535918b/ojk.png",
          width: "137",
          height: "55",
          sizes: "xs:137px lg:137px",
        },
      },
    };
  },
};
